/**
 * Created by Vladislav on 25.09.2024.
 */

var MethaStarsMenuBarItem = {
    name: "MethaStarsItem",
    plusButton: true,
    value: function () {
        return cleverapps.meta.logic.stars;
    },
    updater: function (f) {
        cleverapps.meta.on("changeStars", f, this);
    },
    onClickWindow: GuideWindow,
    onClickOptions: { name: "HomefixRulesWindow" },
    icon: bundles.menubar.frames.menubar_metha_stars,
    availableOnScenes: [cleverapps.Environment.SCENE_MAIN]
};